import React from "react"
import { Label, Input, Textarea, Button, Styled, Box } from "theme-ui"
import Page from "../components/page"
import SEO from "../components/seo"

const Contact = () => {
  // @Note: Complete this form submission
  return (
    <Page>
      <SEO title="Contact" />
      <Styled.h1>Contact</Styled.h1>

      <form
        name="contact"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
        subject="LindseyEatsLA Contact"
      >
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" />

        <Label htmlFor="name" mb={2}>
          Name
        </Label>
        <Input name="name" required mb={3} />

        <Label htmlFor="email" mb={2}>
          Email
        </Label>
        <Input type="email" name="email" required mb={3} />

        <Label htmlFor="message" mb={2}>
          Message
        </Label>
        <Textarea name="message" required rows="6" mb={3} />
        <Box
          css={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            type="submit"
            css={{
              color: "black",
              cursor: "pointer",
              border: "1px solid black",
            }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Page>
  )
}

export default Contact
